import { useState } from 'react';

import useSWR from 'swr';

import { DEFAULT_REFRESH_INTERVAL } from '../../config/constants';
import { fetchWithBearer } from '../../lib/utils';
import { Level, AvatarRarity } from '../../types';

export default function useLevels(
  gameName?: string | null,
  tournamentRarity?: AvatarRarity,
  gameWeekId?: string
) {
  const [active, setActive] = useState(true);

  const { data, error, isLoading, mutate } = useSWR<Level[], Error>(
    gameName && tournamentRarity && gameWeekId
      ? [
          `/games/${gameName}/levels?tournamentRarity=${tournamentRarity}&gameWeekId=${gameWeekId}`,
        ]
      : null,
    fetchWithBearer,
    {
      refreshInterval: active ? DEFAULT_REFRESH_INTERVAL : 0,
      revalidateOnFocus: active,
    }
  );

  return {
    data,
    error,
    isLoading,
    mutate,
    setActive,
  };
}
