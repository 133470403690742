import { Flex, Text } from '@mantine/core';

import DateTimeDisplay from './DateTimeDisplay';

type ShowCounterProps = {
  classes: string;
  days: number;
  hours: number;
  label: string;
  minutes: number;
  seconds: number;
};

export default function ShowCounter({
  classes,
  days,
  hours,
  label,
  minutes,
  seconds,
}: ShowCounterProps) {
  const dangerZone = !!(days === 0 && hours === 0 && minutes <= 10);

  return (
    <Flex fz="xl" px={10} gap={5} className={`show-counter ${classes}`}>
      <Text c={dangerZone ? 'red' : 'white'}>{label}</Text>
      <DateTimeDisplay value={days} type="D" isDanger={dangerZone} />
      <DateTimeDisplay value={hours} type="H" isDanger={dangerZone} />
      <DateTimeDisplay value={minutes} type="M" isDanger={dangerZone} />
      <DateTimeDisplay value={seconds} type="S" isDanger={dangerZone} />
    </Flex>
  );
}
