import {
  ThemeIcon,
  Text,
  Title,
  Container,
  createStyles,
  Stack,
  Flex,
} from '@mantine/core';

import { HOW_IT_WORKS_CONTENTS } from '../../constants/games';

type FeatureProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  index: number;
};

export function Feature({ title, description, index }: FeatureProps) {
  return (
    <Flex>
      <ThemeIcon size={60} radius="lg" mr={10} color="black">
        <Text fz={30} fw={900} c="white">
          {index}
        </Text>
      </ThemeIcon>
      <Stack mt={-3} spacing={0}>
        <Title order={3} c="white" mb={0}>
          {title}
        </Title>
        <Text fw={500} size="md" color="primary.0" style={{ lineHeight: 1.6 }}>
          {description}
        </Text>
      </Stack>
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    marginBottom: theme.spacing.md,
    textAlign: 'center',
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('sm')]: {
      textAlign: 'left',
    },
  },
}));

export default function HowDoesItWorks() {
  const { classes, theme } = useStyles();
  const features = HOW_IT_WORKS_CONTENTS.map((feature, index) => (
    <Feature {...{ ...feature, index: index + 1 }} key={feature.title} />
  ));

  return (
    <Container className={classes.wrapper}>
      <Title
        align="center"
        className="yumon-text-shadow-6"
        fz={{ base: 32, sm: 42, md: 56 }}
        fw={900}
        order={2}
        mb={20}
        italic
        c="black"
      >
        How it works?
      </Title>

      <Container size="sm" p={0}>
        <Stack mt={60} spacing={theme.spacing.xl}>
          {features}
        </Stack>
      </Container>
    </Container>
  );
}
