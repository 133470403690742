import { API_ORIGIN } from '../config';
import { GEMS_PACK_CONFIGS } from '../constants/games';
import { MarketOrder, AllowedGemsPackSizes } from '../types';

const TOURNAMENTS_RARITIES_ORDER = ['common', 'limited', 'epic'];

export function sortByCreatedAt(
  a: { createdAt: string },
  b: { createdAt: string }
) {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
}

export function sortByRarityOrder(
  a: { rarity: string },
  b: { rarity: string }
) {
  return (
    TOURNAMENTS_RARITIES_ORDER.indexOf(a.rarity) -
    TOURNAMENTS_RARITIES_ORDER.indexOf(b.rarity)
  );
}

export function isActive(marketOrder: MarketOrder) {
  return marketOrder.status === 'active';
}

export function getMostRecentActiveMarketOrder(
  marketOrders: MarketOrder[] | undefined
): MarketOrder | undefined {
  return marketOrders?.filter(isActive).sort(sortByCreatedAt)?.[0];
}

export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export function getUsernameByTwitchUrl(url: string) {
  return url.split('.tv/')[1];
}

export function getGemsPackName(quantity: AllowedGemsPackSizes): string {
  const gemsPackConfig = GEMS_PACK_CONFIGS.find(
    (config) => config.quantity === quantity
  );

  if (!gemsPackConfig) {
    throw new Error(`Unknown gems pack size ${quantity}`);
  }

  return gemsPackConfig.name;
}

export async function fetchWithBearer([url, bearer]: string[]) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (bearer?.length) {
    headers.append('Authorization', `Bearer ${bearer}`);
  }

  const res = await fetch(`${API_ORIGIN}${url}`, { headers });

  return res.json();
}
