import { Box, Container, Title, SimpleGrid } from '@mantine/core';

import GemsPack from './GemsPack';
import {
  GEMS_PACK_CONFIGS,
  GEMS_PACK_LARGE_THRESHOLD,
} from '../../constants/games';

export default function GemsMarket() {
  return (
    <Box id="gems" component="section">
      <Container px={20} py={100}>
        <Title
          align="center"
          mb={40}
          fz={{ base: 32, sm: 42, md: 56 }}
          fw={900}
          order={2}
          italic
          c="black"
          className="yumon-text-shadow-6"
        >
          Gems on!
        </Title>
        <Box
          p={20}
          sx={(theme) => ({
            backgroundColor: theme.fn.rgba(theme.colors.primary[7], 0.5),
            border: '2px solid',
            borderColor: theme.colors.primary[7],
            borderRadius: theme.radius.xl,
          })}
        >
          <Title lh={1} mt={20} order={3} fz={38} color="white" align="center">
            Discover our offerings
          </Title>
          <Title c="primary.0" mb={40} order={4} align="center">
            Use them to enter competitions and grow your skills
          </Title>
          <SimpleGrid
            cols={3}
            spacing="xl"
            breakpoints={[
              { maxWidth: 'md', cols: 3, spacing: 'sm' },
              { maxWidth: 'sm', cols: 1, spacing: 'sm' },
            ]}
          >
            {GEMS_PACK_CONFIGS.filter(
              (gemsPackConfig) =>
                gemsPackConfig.quantity < GEMS_PACK_LARGE_THRESHOLD
            ).map((gemsPackConfig) => (
              <GemsPack key={gemsPackConfig.quantity} {...gemsPackConfig} />
            ))}
          </SimpleGrid>
          <SimpleGrid
            cols={2}
            spacing="xl"
            mt="xl"
            breakpoints={[
              { maxWidth: 'md', cols: 2, spacing: 'sm' },
              { maxWidth: 'sm', cols: 1, spacing: 'sm' },
            ]}
          >
            {GEMS_PACK_CONFIGS.filter(
              (gemsPackConfig) =>
                gemsPackConfig.quantity >= GEMS_PACK_LARGE_THRESHOLD
            ).map((gemsPackConfig) => (
              <GemsPack key={gemsPackConfig.quantity} {...gemsPackConfig} />
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}
