import { useEffect } from 'react';

import ExpiredNotice from './ExpiredNotice';
import ShowCounter from './ShowCounter';
import useCountdown from '../../hooks/useCountdown';

type CountdownTimerProps = {
  classes: string;
  expireLabel: string;
  label: string;
  targetDate: string;
  callback?: () => void;
};

export default function CountdownTimer({
  classes,
  expireLabel,
  label,
  targetDate,
  callback,
}: CountdownTimerProps) {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    if (callback && days + hours + minutes + seconds <= 0) {
      callback();
    }
  }, [callback, days, hours, minutes, seconds]);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice expireLabel={expireLabel} />;
  }

  return (
    <ShowCounter
      classes={classes}
      days={days}
      hours={hours}
      label={label}
      minutes={minutes}
      seconds={seconds}
    />
  );
}

CountdownTimer.defaultProps = {
  callback: undefined,
};
