import { useState } from 'react';

import useSWR from 'swr';

import { DEFAULT_REFRESH_INTERVAL } from '../../config/constants';
import { fetchWithBearer } from '../../lib/utils';
import { Game } from '../../types';

export default function useGames() {
  const [active, setActive] = useState(true);

  const { data, error, isLoading, mutate } = useSWR<Game[], Error>(
    ['/games'],
    fetchWithBearer,
    {
      refreshInterval: active ? DEFAULT_REFRESH_INTERVAL : 0,
      revalidateOnFocus: active,
    }
  );

  return {
    data,
    error,
    isLoading,
    mutate,
    setActive,
  };
}
