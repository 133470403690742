import { useCallback } from 'react';

import {
  Box,
  Button,
  Group,
  MediaQuery,
  Stack,
  Title,
  createStyles,
} from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';

import { CDN_ORIGIN } from '../../config';
import { GEMS_PACK_LARGE_THRESHOLD } from '../../constants/games';
import { getGemsPackName } from '../../lib/utils';
import { gtagViewItem } from '../../services/gtag.service';
import { AllowedGemsPackSizes } from '../../types';

const useStyles = createStyles(() => ({
  image: {
    transition: 'all ease-out 4000ms',
    transitionDuration: '400ms',
    '&:hover': {
      scale: `${1.04}`,
    },
  },
}));

export type GemsPackProps = {
  price: string;
  quantity: AllowedGemsPackSizes;
};

export default function GemsPack({ price, quantity }: GemsPackProps) {
  const { classes } = useStyles();

  const handleUnlockClick = useCallback(() => {
    gtagViewItem({
      currency: 'USD',
      items: [
        {
          currency: 'USD',
          itemCategory: 'gems',
          itemId: getGemsPackName(quantity),
          itemName: getGemsPackName(quantity),
          price: Number(price),
          quantity: 1,
        },
      ],
      value: Number(price),
    });
  }, [price, quantity]);

  return (
    <Box
      bg="primary.9"
      p={5}
      m="auto"
      w="100%"
      sx={(theme) => ({
        borderRadius: 20,
        border: '2px solid',
        borderColor: theme.colors.primary[7],
      })}
    >
      <Stack p={10}>
        <Box className={classes.image} sx={{ textAlign: 'center' }}>
          <Link href={`/gems/${quantity}`}>
            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
              <Image
                src={`${CDN_ORIGIN}/studio/gems/packs/${quantity}xGEMS${
                  quantity >= GEMS_PACK_LARGE_THRESHOLD ? '_wide' : ''
                }.png`}
                alt={`${quantity} gems pack`}
                width={quantity >= GEMS_PACK_LARGE_THRESHOLD ? 400 : 200}
                height={200}
              />
            </MediaQuery>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Image
                src={`${CDN_ORIGIN}/studio/gems/packs/${quantity}xGEMS.png`}
                alt={`${quantity} gems pack`}
                width={200}
                height={200}
              />
            </MediaQuery>
          </Link>
        </Box>
        <Group spacing={5}>
          <Title fz={{ base: 'lg', md: 'xl' }} c="white" order={4}>
            {`Gems x ${quantity}`}
          </Title>
          <Title
            fz={{ base: 'lg', md: 'xl' }}
            c="white"
            align="right"
            sx={{ flexGrow: 1 }}
            order={4}
          >
            ${price}
          </Title>
        </Group>
        <Link href={`/gems/${quantity}`} style={{ textDecoration: 'none' }}>
          <Button
            p={5}
            onClick={handleUnlockClick}
            radius={12}
            size="lg"
            fz={{ base: 'sm', md: 'lg' }}
            fullWidth
          >
            Get now
          </Button>
        </Link>
      </Stack>
    </Box>
  );
}
