import {
  createStyles,
  Container,
  Title,
  Button,
  Group,
  Text,
  Box,
  Badge,
  Stack,
  Anchor,
} from '@mantine/core';
import Image from 'next/image';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { SiAppstore } from 'react-icons/si';

import { CDN_ORIGIN } from '../../config';
import {
  YUMON_APPLE_APPSTORE_LINK,
  YUMON_GOOGLE_PLAYSTORE_LINK,
} from '../../config/links';

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },

  content: {
    maxWidth: 480,
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  desktop: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
    [theme.fn.largerThan('xs') && theme.fn.smallerThan('md')]: {
      maxWidth: 400,
      marginTop: -300,
    },
  },

  image: {
    flex: 1,
    zIndex: 0,
    marginTop: -100,

    [theme.fn.smallerThan('md')]: {
      maxWidth: 400,
    },

    [theme.fn.largerThan('xs')]: {
      marginTop: -300,
    },

    [theme.fn.largerThan('sm')]: {
      marginTop: -370,
    },

    [theme.fn.largerThan('md')]: {
      marginTop: -100,
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({
      variant: 'light',
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },
}));

export default function HeaderSection() {
  const { classes } = useStyles();

  return (
    <Box
      component="section"
      w="100%"
      pt={{ base: 50, md: 150 }}
      style={{
        overflow: 'hidden',
        position: 'relative',
        width: '100vw',
        background:
          'linear-gradient(to bottom, rgba(6, 0, 22, 0) 40%, rgba(6, 0, 22, 1) 90%), linear-gradient(to top, rgba(6, 0, 22, 0) 60%, rgba(6, 0, 22, 1) 100%)',
      }}
    >
      <Image
        src={`${CDN_ORIGIN}/studio/textures/landingpage/creator_promise_background_first_landing_page.webp`}
        fill
        alt="Yumon background"
        style={{ objectFit: 'cover', zIndex: -1 }}
      />
      <Container>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Stack>
              <Title
                fz={{ base: 32, sm: 42, md: 52 }}
                order={1}
                italic
                c="black"
                className="yumon-text-shadow-6"
              >
                Casual Games, <br />
                Serious Earnings
              </Title>

              <Title order={2} c="white" fz={{ base: 32, sm: 52 }}>
                Show your skills
              </Title>

              <Text fz={{ base: 18, sm: 22 }} c="white" sx={{ zIndex: 100 }}>
                The
                <Badge
                  fz={{ base: 'sm', sm: 'lg' }}
                  mx={10}
                  px={10}
                  py={5}
                  bg="rgba(0,0,0,0.4)"
                  c="white"
                  opacity={1}
                  radius="sm"
                  leftSection={
                    <Image
                      style={{
                        marginTop: -2,
                        display: 'inline-block',
                        objectFit: 'contain',
                      }}
                      height={20}
                      width={20}
                      src={`${CDN_ORIGIN}/studio/logos/favicon.svg`}
                      alt="Yumon logo"
                    />
                  }
                  sx={{
                    display: 'inline',
                    alignItems: 'center',
                    height: '50px',
                  }}
                >
                  yumon tournaments
                </Badge>
                make you monetize your free-time playing competitive casual and
                super fun games.
              </Text>
            </Stack>

            <Stack mt={30}>
              <Title c="white" fz="lg" sx={{ zIndex: 100 }}>
                DOWNLOAD NOW
              </Title>
              <Group>
                <Anchor href={YUMON_APPLE_APPSTORE_LINK} target="_blank">
                  <Button
                    bg="black"
                    leftIcon={<SiAppstore />}
                    size="md"
                    className={classes.control}
                    sx={{ zIndex: 100 }}
                  >
                    App Store
                  </Button>
                </Anchor>
                <Anchor href={YUMON_GOOGLE_PLAYSTORE_LINK} target="_blank">
                  <Button
                    bg="black"
                    leftIcon={<IoLogoGooglePlaystore />}
                    size="md"
                    className={classes.control}
                    sx={{ zIndex: 100 }}
                  >
                    Google Play
                  </Button>
                </Anchor>
              </Group>
            </Stack>
          </div>
          <Image
            src={`${CDN_ORIGIN}/studio/textures/gamespage/game_qr_code.png`}
            className={(classes.image, classes.desktop)}
            alt="Game QR code background"
            width={500}
            height={600}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </Container>
    </Box>
  );
}
