import { useEffect, useState } from 'react';

import {
  Box,
  Container,
  Flex,
  Button,
  Stack,
  Group,
  Title,
  Text,
  Badge,
  Progress,
  useMantineTheme,
  Grid,
} from '@mantine/core';
import Image from 'next/image';
import { FaChevronDown } from 'react-icons/fa';

import LeaderBoardSkeleton from './LeaderboardSkeleton';
import Player from './Player';
import TopPlayer from './TopPlayer';
import { CDN_ORIGIN } from '../../../config';
import useGames from '../../../hooks/games/useGames';
import useLevels from '../../../hooks/games/useLevels';
import useLiveGameWeeks from '../../../hooks/games/useLiveGameWeeks';
import { capitalizeFirstLetter } from '../../../lib/utils';
import { Game, AvatarRarity } from '../../../types';
import CountdownTimer from '../../countdown-timer';

const RARITIES: AvatarRarity[] = ['common', 'limited', 'epic'];

const DEFAULT_LEADERBOARD_LIMIT = 9;

const PODIUM_SLOT_QUANTITY = 3;

export default function LeaderBoard() {
  const [activeGame, setActiveGame] = useState<Game | null>(null);
  const [activeRarity, setActiveRarity] = useState(RARITIES[0]);
  const [badgeContent, setBadgeContent] = useState('');
  const [gameEndDate, setGameEndDate] = useState<Date | undefined>();
  const [gameStartDate, setGameStartDate] = useState<Date | undefined>();
  const [gameProgression, setGameProgression] = useState(0);
  const [leaderBoardLimit, setLeaderBoardLimit] = useState(
    DEFAULT_LEADERBOARD_LIMIT
  );

  const [globalPricePool, setGlobalPricePool] = useState(0);

  const { data: games } = useGames();

  const { data: gameWeeks } = useLiveGameWeeks();

  const gameWeek = gameWeeks?.[0];

  const theme = useMantineTheme();

  useEffect(() => {
    const updatedGameStartDate = new Date(gameWeek?.gameStart ?? '');
    const updatedGameEndDate = new Date(gameWeek?.gameEnd ?? '');

    const totalGameDuration =
      updatedGameEndDate.getTime() - updatedGameStartDate.getTime();
    const timeSinceGameStart =
      new Date().getTime() - updatedGameStartDate.getTime();

    if (
      !Number.isNaN(Number(updatedGameStartDate)) &&
      !Number.isNaN(Number(updatedGameEndDate))
    ) {
      setBadgeContent(
        `${updatedGameStartDate.toLocaleDateString()} - ${updatedGameEndDate.toLocaleDateString()}`
      );

      setGameStartDate(updatedGameStartDate);
      setGameEndDate(updatedGameEndDate);

      setGameProgression((timeSinceGameStart * 100) / totalGameDuration);
    }
  }, [gameWeek?.gameEnd, gameWeek?.gameStart]);

  const {
    data: levels,
    isLoading: scoresLoading,
    error: scoresError,
  } = useLevels(activeGame?.name, activeRarity, gameWeek?.id);

  useEffect(() => {
    if (games?.length) {
      setActiveGame(games[0]);

      const pool = games
        .map((game) =>
          (game.rewards ?? []).reduce((acc, cur) => acc + Number(cur.amount), 0)
        )
        .reduce((acc, cur) => acc + cur, 0);

      setGlobalPricePool(pool);
    }
  }, [games]);

  const handleTabChange = (value: string) => {
    const updatedActiveGame = games?.find((game) => game.displayName === value);

    if (updatedActiveGame) {
      setActiveGame(updatedActiveGame);
    }
  };

  return (
    <Box component="section" bg="primary.9" w="100%">
      <Container>
        <Box
          pb={40}
          mt={50}
          sx={{
            position: 'relative',
            width: '100%',
            backgroundColor: theme.fn.rgba(theme.colors.primary[7], 0.5),
            border: '2px solid',
            borderColor: theme.colors.primary[7],
            borderRadius: theme.radius.xl,
            overflow: 'hidden',
          }}
        >
          <Box h={350} style={{ overflow: 'hidden', position: 'relative' }}>
            <Image
              src={`${CDN_ORIGIN}/studio/banners/games_banner.webp`}
              fill
              alt="Games banner background"
              style={{ objectFit: 'cover' }}
            />
            <Flex align="center" justify="flex-end" h="100%">
              <Stack
                mr={{ base: 0, sm: 20 }}
                bg={{ base: 'rgba(0,0,0,.5)', sm: 'rgba(0,0,0,0)' }}
                justify="center"
                align="center"
                h="100%"
                w={{ base: '100%', sm: '40%' }}
                spacing={5}
                sx={{ zIndex: 2 }}
              >
                {gameStartDate &&
                gameEndDate &&
                new Date() > gameStartDate &&
                new Date() < gameEndDate ? (
                  <>
                    <Title fz={34} order={2}>
                      World Cup{' '}
                      <Text span c="red" inherit>
                        LIVE
                      </Text>
                    </Title>

                    <Badge
                      size="xl"
                      px={10}
                      bg="rgba(0,0,0,0.4)"
                      c="white"
                      opacity={1}
                      radius="lg"
                      sx={{ display: 'inline' }}
                    >
                      {badgeContent}
                    </Badge>

                    <CountdownTimer
                      expireLabel="Ended."
                      label="Ends in"
                      targetDate={gameWeek?.gameEnd ?? ''}
                      classes=""
                    />

                    <Progress
                      aria-label="Game week progress"
                      mt={5}
                      w="240px"
                      color="teal"
                      radius="lg"
                      size="lg"
                      value={gameProgression}
                    />

                    {globalPricePool > 0 && (
                      <Text
                        mt={25}
                        size="md"
                        italic
                        fz="xl"
                        fw={700}
                        c="white"
                        align="center"
                      >
                        GLOBAL PRIZE POOL: <br />
                        <b
                          style={{
                            lineHeight: '1em',
                            color: theme.colors.teal[4],
                            fontSize: 40,
                          }}
                        >
                          <Image
                            width={35}
                            height={35}
                            src={`${CDN_ORIGIN}/studio/users/GEMS_100x100.png`}
                            alt="gems prize pool"
                            quality={100}
                          />
                          {globalPricePool}
                        </b>
                      </Text>
                    )}
                  </>
                ) : (
                  <Title fz={34} order={2}>
                    World Cup{' '}
                    <Text span c="red" inherit>
                      CLOSED
                    </Text>
                  </Title>
                )}
              </Stack>
            </Flex>
          </Box>

          <Stack align="center" mt={50} mih={680}>
            <Group>
              {games?.map((game) => (
                <Button
                  onClick={() => handleTabChange(game.displayName)}
                  key={game.name}
                  value={game.displayName}
                  fw={700}
                  variant={game === activeGame ? 'filled' : 'subtle'}
                >
                  {game.displayName}
                </Button>
              ))}
            </Group>

            <Group>
              {RARITIES.map((rarity) => (
                <Button
                  key={`select-${rarity}-scores`}
                  p={0}
                  variant="subtle"
                  c={activeRarity === rarity ? 'primary.2' : 'white'}
                  onClick={() => setActiveRarity(rarity)}
                >
                  {capitalizeFirstLetter(rarity)}
                </Button>
              ))}
            </Group>

            {scoresLoading && <LeaderBoardSkeleton />}

            {activeGame && !scoresError && levels && levels.length > 0 && (
              <Stack align="center">
                <Flex
                  direction={{ base: 'column', xs: 'row' }}
                  align="center"
                  justify="center"
                  gap="xl"
                  pt={100}
                >
                  {levels
                    .filter(
                      (level) =>
                        level.score !== undefined && level.score !== null
                    )
                    .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
                    .slice(0, PODIUM_SLOT_QUANTITY)
                    .map(
                      ({ score, user }, index) =>
                        user && (
                          <TopPlayer
                            key={user.paginationId}
                            avatar={user.profilePictureUrl ?? ''}
                            name={user.username}
                            rank={index + 1}
                            reward={
                              (activeGame.rewards ?? []).find(
                                (reward) =>
                                  reward.rarity === activeRarity &&
                                  reward.position === index + 1
                              )?.amount ?? ''
                            }
                            score={score ?? 0}
                          />
                        )
                    )}
                </Flex>

                <Grid w="100%" maw={700} mx="auto">
                  {levels
                    .filter(
                      (level) =>
                        level.score !== undefined && level.score !== null
                    )
                    .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
                    .slice(PODIUM_SLOT_QUANTITY, leaderBoardLimit)
                    .map(
                      ({ score, user }, index) =>
                        user && (
                          <Grid.Col span={12} sm={6} key={user.paginationId}>
                            <Player
                              avatar={user.profilePictureUrl ?? ''}
                              name={user.username}
                              rank={index + PODIUM_SLOT_QUANTITY + 1}
                              reward={
                                (activeGame.rewards ?? []).find(
                                  (reward) =>
                                    reward.rarity === activeRarity &&
                                    reward.position ===
                                      index + PODIUM_SLOT_QUANTITY + 1
                                )?.amount ?? ''
                              }
                              score={score ?? 0}
                            />
                          </Grid.Col>
                        )
                    )}
                </Grid>

                <Button
                  mt={20}
                  variant="subtle"
                  onClick={() =>
                    setLeaderBoardLimit(
                      leaderBoardLimit + DEFAULT_LEADERBOARD_LIMIT
                    )
                  }
                >
                  <FaChevronDown size={30} title="Load more players" />
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
