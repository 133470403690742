import { Box } from '@mantine/core';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { SWRConfig, unstable_serialize } from 'swr';

import FrequentlyAskedQuestions from '../components/games/FrequentlyAskedQuestions';
import GemsMarket from '../components/games/GemsMarket';
import HeaderSection from '../components/games/HeaderSection';
import HowDoesItWorks from '../components/games/HowItWorks';
import LeaderBoard from '../components/games/leaderboard';
import JoinCommunitySection from '../components/JoinCommunitySection';
import { API_ORIGIN, CDN_ORIGIN } from '../config';
import { Game, GameWeek, Level } from '../types';

type GamesPageProps = {
  fallback: Level[];
};

export default function GamesPage({ fallback }: GamesPageProps) {
  return (
    <>
      <Head>
        <title>Yumon • Games</title>
      </Head>
      <Box component="main">
        <HeaderSection />
        <SWRConfig value={{ fallback }}>
          <LeaderBoard />
        </SWRConfig>
        <Box
          style={{
            overflow: 'hidden',
            position: 'relative',
            width: '100vw',
            background:
              'linear-gradient(to bottom, rgba(6, 0, 22, 0) 30%, rgba(6, 0, 22, 1) 100%), linear-gradient(to top, rgba(6, 0, 22, 0) 30%, rgba(6, 0, 22, 1) 90%)',
          }}
        >
          <Image
            src={`${CDN_ORIGIN}/studio/textures/landingpage/creator_promise_background_first_landing_page.webp`}
            fill
            alt="Yumon background"
            style={{ objectFit: 'cover', zIndex: -1 }}
          />
          <GemsMarket />
          <HowDoesItWorks />
        </Box>
        <FrequentlyAskedQuestions />
        <JoinCommunitySection />
      </Box>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async () => {
  const gamesKey = '/games';
  const gameWeeksKey = '/games/game-weeks?live=true';

  const games = await fetch(`${API_ORIGIN}${gamesKey}`);
  const gameWeeks = await fetch(`${API_ORIGIN}${gameWeeksKey}`);

  return {
    props: {
      fallback: {
        [unstable_serialize([gamesKey])]: (await games.json()) as Game[],
        [unstable_serialize([gameWeeksKey])]:
          (await gameWeeks.json()) as GameWeek[],
      },
    },
  };
};
