import {
  Button,
  Box,
  Container,
  Group,
  Stack,
  Text,
  Title,
  Anchor,
} from '@mantine/core';
import { FaTwitter, FaDiscord } from 'react-icons/fa';

import { YUMON_DISCORD_WEB_LINK } from '../config/links';

export default function JoinCommunitySection() {
  return (
    <Box component="section" bg="primary.9" w="100%" pb={100}>
      <Container size="xs">
        <Box
          py={40}
          px={20}
          bg="primary.8"
          sx={(theme) => ({ width: '100%', borderRadius: theme.radius.xl })}
        >
          <Stack>
            <Title order={2} align="center" c="white">
              Join the community
            </Title>
            <Group noWrap align="center" grow>
              <Anchor
                sx={{ display: 'inline-block' }}
                href={YUMON_DISCORD_WEB_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <Button fullWidth>
                  <FaDiscord size={20} />
                  <Text ml={10} w="white">
                    Discord
                  </Text>
                </Button>
              </Anchor>
              <Anchor
                sx={{ display: 'inline-block' }}
                href="https://twitter.com/YUMONworld"
                target="_blank"
                rel="noreferrer"
              >
                <Button fullWidth>
                  <FaTwitter size={20} />
                  <Text ml={10} w="white">
                    Twitter
                  </Text>
                </Button>
              </Anchor>
            </Group>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
