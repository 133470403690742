import {
  Container,
  Text,
  Title,
  Accordion,
  createStyles,
  Box,
} from '@mantine/core';

import { QUESTIONS } from '../../constants/games';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    minHeight: 650,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    backgroundColor: theme.colors.primary[9],
    border: `1px solid ${theme.colors.primary[6]}`,
    overflow: 'hidden',
    '[data-active]': {
      backgroundColor: theme.colors.primary[6],
      borderColor: theme.colors.primary[6],
    },
  },

  panel: {
    backgroundColor: 'transparent',
    '[data-active]': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function FrequentlyAskedQuestions() {
  const { classes } = useStyles();

  return (
    <Box component="section" bg="primary.9" w="100%">
      <Container size="sm" className={classes.wrapper}>
        <Title
          align="center"
          className="yumon-text-shadow-6"
          fz={{ base: 32, sm: 42, md: 56 }}
          fw={900}
          order={2}
          mb={20}
          italic
          c="black"
        >
          FAQ
        </Title>

        <Accordion variant="separated">
          {QUESTIONS.map((curr) => (
            <Accordion.Item
              key={`accordion-${curr.question}`}
              className={classes.item}
              value={curr.question}
            >
              <Accordion.Control c="white" fz={18}>
                <Text fw={600} c="white">
                  {curr.question}
                </Text>
              </Accordion.Control>
              <Accordion.Panel
                opacity={1}
                bg="primary.9"
                className={classes.panel}
              >
                {curr.answers.map((answer) => (
                  <Text fw={500} mt="sm" key={answer}>
                    {answer}
                  </Text>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </Box>
  );
}
