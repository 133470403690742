import { Flex, Grid, Skeleton, Stack } from '@mantine/core';

export default function LeaderBoardSkeleton() {
  return (
    <Stack align="center" w="100%">
      <Flex
        direction={{ base: 'column', xs: 'row' }}
        align="center"
        justify="center"
        gap="xl"
        pt={{ base: 20, md: 100 }}
      >
        <Skeleton
          opacity={0.3}
          bg="primary.8"
          w={{ base: 150, md: 195 }}
          h={{ base: 150, md: 180 }}
          radius="xl"
        />
        <Skeleton
          mt={{ md: -75 }}
          opacity={0.3}
          bg="primary.8"
          w={{ base: 150, md: 195 }}
          h={{ base: 150, md: 180 }}
          radius="xl"
        />
        <Skeleton
          opacity={0.3}
          bg="primary.8"
          w={{ base: 150, md: 195 }}
          h={{ base: 150, md: 180 }}
          radius="xl"
        />
      </Flex>

      <Grid w="100%" maw={700} mx="auto" mt={20}>
        <Grid.Col span={12} sm={6}>
          <Skeleton
            opacity={0.3}
            bg="primary.8"
            width="100%"
            height={50}
            radius="md"
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Skeleton
            opacity={0.3}
            bg="primary.8"
            width="100%"
            height={50}
            radius="md"
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Skeleton
            opacity={0.3}
            bg="primary.8"
            width="100%"
            height={50}
            radius="md"
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Skeleton
            opacity={0.3}
            bg="primary.8"
            width="100%"
            height={50}
            radius="md"
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Skeleton
            opacity={0.3}
            bg="primary.8"
            width="100%"
            height={50}
            radius="md"
          />
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Skeleton
            opacity={0.3}
            bg="primary.8"
            width="100%"
            height={50}
            radius="md"
          />
        </Grid.Col>
      </Grid>
    </Stack>
  );
}
