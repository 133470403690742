import { Box, Text } from '@mantine/core';

type ExpiredNoticeProps = {
  expireLabel: string;
};

export default function ExpiredNotice({ expireLabel }: ExpiredNoticeProps) {
  return (
    <Box className="expired-notice">
      <Text>{expireLabel}</Text>
    </Box>
  );
}
