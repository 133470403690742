import { Avatar, Text, Paper, ThemeIcon, Box, Flex } from '@mantine/core';
import Image from 'next/image';

import { CDN_ORIGIN } from '../../../config';

type PlayerProps = {
  avatar: string;
  name: string;
  score: number;
  rank: number;
  reward: string;
};

export default function Player({
  avatar,
  name,
  score,
  reward,
  rank,
}: PlayerProps) {
  return (
    <Box
      pl={{ base: 30, sm: 10 }}
      pr={{ base: 20, sm: 0 }}
      w={{ base: '100%' }}
    >
      <Paper
        radius="lg"
        withBorder
        w={{ base: '100%' }}
        p={10}
        sx={(theme) => ({
          backgroundColor: theme.colors.primary[8],
          borderColor: theme.colors.primary[7],
        })}
      >
        <Flex wrap="nowrap" gap="sm" align="center" fw={600}>
          <ThemeIcon size={24} radius="sm" ml={-20} mr={0} color="white">
            <Text fz={{ base: 12, sm: 18 }} c="black" fw={900}>
              {rank}
            </Text>
          </ThemeIcon>
          <Avatar size={32} radius="md">
            <Image
              src={avatar}
              width={32}
              height={32}
              alt={`${name}'s profile picture`}
            />
          </Avatar>
          <Text
            w="100%"
            truncate
            sx={{ flexGrow: 1 }}
            align="left"
            c="white"
            fz="sm"
          >
            {name}
          </Text>
          <Flex wrap="nowrap" align="center" gap="xs">
            <Text inline align="center" c="white">
              {score}
            </Text>
            {reward.length > 0 && (
              <Flex
                wrap="nowrap"
                gap="xs"
                align="center"
                sx={{ gap: 0, margin: 0 }}
              >
                <Image
                  width={15}
                  height={15}
                  src={`${CDN_ORIGIN}/studio/users/GEMS_100x100.png`}
                  alt="gems reward"
                  quality={100}
                />
                <Text inline align="center" c="teal">
                  {reward}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Paper>
    </Box>
  );
}
