import { Flex, Text } from '@mantine/core';

type DateTimeDisplayProps = {
  value: number;
  type: string;
  isDanger: boolean;
};

export default function DateTimeDisplay({
  value,
  type,
  isDanger,
}: DateTimeDisplayProps) {
  return (
    <Flex c={isDanger ? 'red' : 'white'}>
      <Text c="white">{value}</Text>
      <Text c="white">{type}</Text>
    </Flex>
  );
}
