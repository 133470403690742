import {
  Avatar,
  Text,
  Paper,
  Group,
  Stack,
  Badge,
  ThemeIcon,
} from '@mantine/core';
import Image from 'next/image';

import { CDN_ORIGIN } from '../../../config';

type TopPlayerProps = {
  avatar: string;
  name: string;
  rank: number;
  reward: string;
  score: number;
};

export default function TopPlayer({
  avatar,
  name,
  rank,
  reward,
  score,
}: TopPlayerProps) {
  return (
    <Paper
      w={{ base: 150, sm: 200 }}
      mt={rank === 1 ? -75 : 0}
      mb={20}
      radius="xl"
      withBorder
      p="lg"
      sx={(theme) => ({
        [theme.fn.largerThan(theme.breakpoints.xs)]: {
          order: rank < 3 ? rank * -2 : rank,
        },
        order: rank,
        backgroundColor: theme.colors.primary[8],
        borderColor: theme.colors.primary[7],
      })}
    >
      <Stack align="center" spacing={0}>
        <Avatar size={80} radius="lg" mx="auto" mt={-50}>
          <Image
            src={avatar}
            width={80}
            height={80}
            alt={`${name}'s profile picture`}
          />
        </Avatar>
        <ThemeIcon
          pt={2}
          mt={-12}
          size={24}
          radius="sm"
          color="white"
          sx={{ zIndex: 999 }}
        >
          <Text fz={{ base: 12, sm: 18 }} fw={900} c="black">
            {rank}
          </Text>
        </ThemeIcon>
        <Text
          fz={{ base: 16, sm: 20 }}
          fw={900}
          align="center"
          mt="md"
          c="white"
        >
          {name}
        </Text>
        {rank === 1 && (
          <Badge
            fz={8}
            pt={2}
            px={4}
            m="auto"
            component="span"
            variant="outline"
            color="teal"
          >
            BEST SCORE
          </Badge>
        )}
        <Group mt="sm" align="center" position="center">
          <Stack spacing={3}>
            <Text
              fz={{ base: 8, sm: 'xs' }}
              inline
              align="center"
              color="dimmed"
            >
              SCORE
            </Text>
            <Text inline align="center" c="white" fw={700}>
              {score}
            </Text>
          </Stack>
          {reward.length > 0 && (
            <Stack spacing={3}>
              <Text fz={{ base: 8, sm: 'xs' }} inline align="center" c="dimmed">
                REWARD
              </Text>
              <Group position="center" spacing="xs" sx={{ gap: 0, margin: 0 }}>
                <Image
                  width={15}
                  height={15}
                  src={`${CDN_ORIGIN}/studio/users/GEMS_100x100.png`}
                  alt="gems reward"
                  quality={100}
                />
                <Text inline align="center" c="teal" fw={700}>
                  {reward}
                </Text>
              </Group>
            </Stack>
          )}
        </Group>
      </Stack>
    </Paper>
  );
}
