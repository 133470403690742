import { CDN_ORIGIN } from '../config';
import { AllowedGemsPackSizes } from '../types';

export type GemsPackConfig = {
  id: string;
  name: string;
  price: string;
  quantity: AllowedGemsPackSizes;
};

type Question = {
  question: string;
  answers: string[];
};

export const APP_QR_CODE = `${CDN_ORIGIN}/studio/textures/gamespage/arcade-game-qr-code-placeholder.png`;

export const GEMS_PACK_LARGE_THRESHOLD = 600;

export const GEMS_PACK_CONFIGS: GemsPackConfig[] = [
  {
    id: 'gems.pack.one',
    name: 'Pile of Gems',
    price: '0.99',
    quantity: 100,
  },
  {
    id: 'gems.pack.two',
    name: 'Bag of Gems',
    price: '1.99',
    quantity: 200,
  },
  {
    id: 'gems.pack.three',
    name: 'Bucket of Gems',
    price: '2.99',
    quantity: 300,
  },
  {
    id: 'gems.pack.four',
    name: 'Box of Gems',
    price: '5.99',
    quantity: 600,
  },
  {
    id: 'gems.pack.five',
    name: 'Chest of Gems',
    price: '9.99',
    quantity: 1000,
  },
];

export const HOW_IT_WORKS_CONTENTS = [
  {
    title: 'Download our games on the app stores',
    description:
      'New games with popular gameplays are joining the Yumon ecosystem every month. Games are free to download and free from intrusive ads. You can play in practice mode or compete against other players to win rewards. You need to be authenticated to join the competition.',
  },
  {
    title: 'Get Avatars & Compete',
    description:
      'Showcase your skills competing against players from around the world to rise up the rankings and earn rewards. Receive your first competition-ready avatar as soon as you authenticate in a game. Collect Gems and Buy lives to your Limited and Epic avatars to unlock them and win big in their corresponding leagues!',
  },
  {
    title: 'Get Rewarded',
    description:
      'Play the adventure mode, collect gems and enter competitions! The top players ranked by points get rewarded with game items and real-life prizes! Play, Win, Cash out. Repeat.',
  },
  {
    title: 'Trade your Avatars',
    description:
      'Rank up faster or switch game at no cost . You can buy avatars from other players who have spent time and money in Yumon. If you want to quit Yumon, simply sell your avatars with their acquired experience and lives. Your time and money is never lost.',
  },
];

export const QUESTIONS: Question[] = [
  {
    question: 'How do I join?',
    answers: ['Simply download a game from the Yumon ecosystem and signup.'],
  },
  {
    question: 'Can I play multiple times with my avatar?',
    answers: [
      'You can play as many games as you want for free and without ads.',
      'When playing a World Cup, the number of lives that your avatars have set the number of tries you have for each leagues.',
      'When running out of lives, you can collect additional gems in the game or buy them in the game shops or right here on this page using your credit card or crypto wallet.',
    ],
  },
  {
    question: 'Can use my Avatar(s) in other games?',
    answers: [
      'All Avatars are cross-games, you can use them in all games of the Yumon ecosystem.',
    ],
  },
  {
    question: 'Can I trade my used avatars?',
    answers: [
      'You can trade your avatar on the Yumon Marketplace if you want to.',
      'New features will be released to create real momentums on avatar trading.',
    ],
  },
  {
    question: 'When are prizes distributed?',
    answers: [
      'Prizes are distributed at the end of each World Cup and at the end of each Brawl when playing this game mode.',
      'Rewards are distributed in Gems and your can convert your Gems into Rewards, redeem them directly from the Prize section of the games.',
      'Rewards are directly transferred to your Yumon wallet if you have chosen to receive Crypto, instructions for other rewards are sent by email.',
    ],
  },
  {
    question: 'How is scoring working for the games?',
    answers: [
      'Games are very straight forward.',
      'In each game you have to reach the maximum score, running or collecting items as much as you can in a single run.',
      'Reach the maximum score to rise up the rankings and earn rewards.',
    ],
  },
  {
    question: 'What kind of games should we be expecting?',
    answers: [
      'Mostly 3D arcade-style runners.',
      'Feel free to suggest your ideas and reach out to us through Discord or Twitter.',
    ],
  },
];
